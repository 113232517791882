<template>
  <v-dialog
    v-model="dialog"
    max-width="500px">
    <!-- <template v-slot:activator="{ on }">
      <v-btn
        dark
        class="mb-2"
        v-on="on"
      >
        Modal button
      </v-btn
      >
    </template> -->
    <v-card>
      <v-card-title class="pa-1">
        <img
          :src="appLogo"
          alt="site logo"
          width="240"
          height="50">
      </v-card-title>

      <v-card-text class="pa-1">
        <div class="container d-flex align-center justify-center">
          <div class="secretary-join-content">
            <h2>Je bent uitgenodigd als assistent</h2>
            <hr>
            <v-spacer/>
            <p class="mt-4">Beste <b>{{ secretaryFirstName }}</b>,</p>
            <p class="mt-2">Je bent uitgenodigd door <b>{{ passengerFirstName }} {{ passengerLastName }}</b></p>
            <p class="mt-2">Als assistent kun je bij DriveMe een privéchauffeur boeken voor <b>{{ passengerFirstName }}</b>, maak ook voor jezelf.</p>
            <p class="mt-5 mb-0">Vriendelijke groet,</p>
            <p>Het DriveMe Team</p>
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer/>
        <v-btn
          :loading="loading"
          color="secondary"
          @click="accept">Accepteer</v-btn>

        <v-btn
          :disabled="loading"
          color="secondary"
          flat
          @click="close">Weiger</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Secretary from '../../helpers/secretary'
import AppConfig from '../../constants/AppConfig'

export default {
  mixins: [Secretary],
  data () {
    return {
      appLogo: AppConfig.appLogo,
      dialog: false,
      passengerFirstName: '',
      passengerLastName: '',
      secretaryFirstName: '',
      loading: false
    }
  },
  async beforeMount () {
    if (this.$route.query.token) {
      /* POST /api/secretary-invite/approve {token}
        response "state" can have these values:
          - signup
          - login
          - logout (means that a current session user is not an invited person)
          - redirect
      */

      try {
        const { data: secretaryInviteData } = await this.getSecretaryInvite(this.$route.query.token)

        this.secretaryFirstName = secretaryInviteData.master.firstName
        this.passengerFirstName = secretaryInviteData.client.firstName
        this.passengerLastName = secretaryInviteData.client.lastName

        await this.handleSecretaryInviteApprove({
          state: secretaryInviteData.state,
          email: secretaryInviteData.master.email
        })

        this.dialog = true
      } catch (error) {
        setTimeout(() => {
          window.location.href = '/'
        }, 3000)
      }
    }
  },
  methods: {
    async handleSecretaryInviteApprove ({ state, email }, redirect) {
      switch (state) {
        case 'signup':
          localStorage.setItem('secretaryInviteToken', this.$route.query.token)
          localStorage.setItem('secretaryEmail', email)
          await this.killToken()
          this.$router.push('/signup')
          break

        case 'login':
          localStorage.setItem('secretaryInviteToken', this.$route.query.token)
          localStorage.setItem('secretaryEmail', email)
          this.$router.push('/session/login')
          break

        case 'logout':
          await this.killToken()
          this.$store.dispatch('logoutUserFromFirebase', this.$router)
          break

        case 'redirect':
          if (redirect) {
            window.location.href = '/'
          } else {
            this.dialog = true
          }
          break

        default:
          window.location.href = '/'
          break
      }
    },
    async close () {
      try {
        this.loading = true

        await this.secretaryInviteReject(this.$route.query.token)

        window.location.href = '/'
      } catch (error) {
        this.loading = false
      }
    },
    async accept () {
      try {
        this.loading = true

        const { data } = await this.secretaryInviteApprove(this.$route.query.token)

        if (data && data.state) {
          await this.handleSecretaryInviteApprove(data, true)
        }
      } catch (error) {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
